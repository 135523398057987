<template>
    <div class="center">
        <img src="/media/images/elbast_flyer_header.original.png" width="612" height="81" border="0" style="width:612px;" >

        <div v-if="error">
            <div class="alert alert-danger mt-3" role="alert">
            <i class="bi bi-x-octagon-fill me-2"></i> {{error}}
            </div>
        </div>

        <div v-else>
            <a :href="link_url"><img :src="image_path"  style="width:612px;" /></a>
            
            <div style="width:612px;">
                <hr>
                <div class="font_small">
                    ABOUT Ma Labs: Founded in 1983, Ma Labs is a global IT distributor providing a comprehensive range of product lines to VARs, System Integrators and OEM customers worldwide. The company works with leading industry suppliers of IT systems, peripherals, PC components, software and networking equipment. Please visit www.malabs.com for additional information.
                </div>
                <table class="font_small mt-2 mb-2">
                    <tr>
                        <td width="18%">
                            <div><a href="mailto:sales@malabs.com" class="fw-bold">SAN JOSE (HQ)</a></div>
                            <div>408.941.0808</div>
                            <div><a href="mailto:sales@malabs.com">sales@malabs.com</a></div>
                        </td>
                        <td  width="18%">
                            <div><a href="mailto:sales.ch@malabs.com" class="fw-bold">CHICAGO</a></div>
                            <div>630.893.2323</div>
                            <div><a href="mailto:sales.ch@malabs.com">sales.ch@malabs.com</a></div>
                        </td>
                        <td  width="18%">
                            <div><a href="mailto:sales.ga@malabs.com" class="fw-bold">GEORGIA</a></div>
                            <div>770.209.6600</div>
                            <div><a href="mailto:sales.ga@malabs.com">sales.ga@malabs.com</a></div>
                        </td>
                        <td  width="18%">
                            <div><a href="mailto:sales.la@malabs.com" class="fw-bold">LOS ANGELES</a></div>
                            <div>626.820.8988</div>
                            <div><a href="mailto:sales.la@malabs.com">sales.la@malabs.com</a></div>
                        </td>
                        <td width="18%">
                            <div><a href="mailto:sales.mi@malabs.com" class="fw-bold">MIAMI</a></div>
                            <div>305.594.8700</div>
                            <div><a href="mailto:sales.mi@malabs.com">sales.mi@malabs.com</a></div>
                        </td>
                        <td width="17%">
                            <div><a href="mailto:sales.nj@malabs.com" class="fw-bold">NEW JERSEY</a></div>
                            <div>732.661.3388</div>
                            <div><a href="mailto:sales.nj@malabs.com">sales.nj@malabs.com</a></div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <img src="/media/images/eblast_flyer_footer.original.jpg" width="612" border="0" style="width:612px;" >
    </div>
  
</template>

<script>
import { ref } from "vue"
import { getAPI } from '../../utils/axios-api'
import { useRoute } from "vue-router"

export default {
    name: 'Quote',
    setup() {
        const image_path = ref(null)
        const link_url = ref(null)
        const error = ref(null)
        const route = useRoute()

        //get url parameters
        var id 
        if (typeof route.query.id != "undefined" && route.query.id != "") {
            id = route.query.id
        }

        getAPI.get('/account/eblast/flyer/' + id)
        .then(response => {
            var result = response.data
            if (result.status == "done") {
                image_path.value = result.image
                link_url.value = result.url
            }
            else {
                error.value = result.message
            }
        })
        .catch(err => {
            try {
                error.value = err.response.data.message
            }
            catch {
                error.value = "Something is wrong"
            }
            
        })

        return {image_path, link_url, error}
    }
}
</script>

<style scoped>
.center {
    margin: auto;
    max-width:40%;
    padding: 0px;
}
.font_small {
    font-size: 8px;
}
table a {color: #000;}
</style>